import React, { Component } from 'react'
import { Provider } from '../context'
import decode from 'jwt-decode'
import { setItem, getItem, removeItem, clear } from '../../../../storage.js'
import jose from 'node-jose'

// const TOKEN_CHECK_INTERVAL = 60 * 100 // Check every 60 seconds

export const decryptJwe = async token => {
  const JWKCONFIG = {
    Secret_Key: 'VECV_Sch',
    exportedJwk: {
      kty: 'oct',
      kid: '7bDRV_JGMqFHuzPVF9OvoorYMJhwAGNsdcJ40nNLZbw',
      alg: 'A256GCM',
      k: 'ZCY9_Kfd67kPpVeODJe2GULFwHiqjrHQN483zCeW7jU'
    }
  }
  try {
    const key = await jose.JWK.asKey(JWKCONFIG.exportedJwk)
    const decryptedJwe = await jose.JWE.createDecrypt(key).decrypt(token)
    const data = decryptedJwe.payload.toString('utf-8')
    return JSON.parse(data)
  } catch (err) {
    console.log('err while decrypting jwe:', err)
    return err
  }
}

export const isAuthenticated = async () => {
  const token = getItem('token', 'PERSISTENT') || getItem('token', 'TEMPORARY')
  if (!token) {
    return false
  }

  try {
    const decodedToken = decode(token)
    const { exp } = await decryptJwe(decodedToken)
    const cur = Math.round(new Date().getTime() / 1000)

    console.log('exp', exp)

    if (cur >= exp) {
      clear('PERSISTENT')
      clear('TEMPORARY')
      return false
    }
  } catch (err) {
    console.log('Error during authentication check:', err)
    return false
  }
  return true
}

class AuthProvider extends Component {
  state = {
    authStatus: false
  }

  componentDidMount() {
    this.checkAuthStatus()

    // // Set up an interval to check token expiration periodically
    // this.tokenCheckInterval = setInterval(
    //   this.checkAuthStatus,
    //   TOKEN_CHECK_INTERVAL
    // )
  }

  componentWillUnmount() {
    // Clear the interval when component unmounts
    clearInterval(this.tokenCheckInterval)
  }

  /**
   * Function to check authentication status and automatically log out if token has expired
   */
  checkAuthStatus = async () => {
    const isAuthenticatedFlag = await isAuthenticated()
    if (!isAuthenticatedFlag) {
      this.logout()
    } else {
      this.setState({ authStatus: true })
    }
  }

  login = async ({ token, username, loginId, accountType }) => {
    setItem('token', token, 'PERSISTENT')
    setItem('username', username, 'PERSISTENT')
    setItem('loginId', loginId, 'PERSISTENT')
    setItem('accountType', accountType, 'PERSISTENT')
    setItem('loginType', 'PASSWORD', 'PERSISTENT')
    this.setState({ authStatus: true })
  }

  logout = () => {
    const loginType =
      getItem('loginType', 'PERSISTENT') || getItem('loginType', 'TEMPORARY')

    if (loginType === 'PASSWORD') {
      removeItem('token', 'PERSISTENT')
      removeItem('username', 'PERSISTENT')
      removeItem('loginId', 'PERSISTENT')
      removeItem('loginType', 'PERSISTENT')
    } else {
      removeItem('token', 'TEMPORARY')
      removeItem('username', 'TEMPORARY')
      removeItem('loginId', 'TEMPORARY')
      removeItem('loginType', 'TEMPORARY')
    }

    this.setState({ authStatus: false })
  }

  render() {
    const value = {
      ...this.state,
      login: this.login,
      logout: this.logout
    }

    return <Provider value={value}>{this.props.children}</Provider>
  }
}

export default AuthProvider
