/**
 * @module apolloClient
 * @summary Exports a function that sets up apollo-client
 */

import ApolloClient from 'apollo-client/ApolloClient'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { ApolloLink, split } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { getMainDefinition } from 'apollo-utilities'
import { onError } from 'apollo-link-error'
import { getItem } from '../../../storage.js'
import { isAuthenticated } from '@zeliot/common/auth/components/AuthProvider'
import { createUploadLink } from 'apollo-upload-client'
import axios from 'axios'
import aesjs from 'aes-js'

var key = [23, 12, 75, 34, 89, 43, 65, 32, 92, 45, 12, 75, 27, 67, 44, 54]

function createResponseFromAxiosResp(axiosResp, data) {
  data = data || axiosResp.data
  // use Response will cause url be empty, as apollo-link-http use response.text() to get string data
  let ret = new Response(JSON.stringify(axiosResp.data), {
    ...axiosResp
  })
  return ret
}

/**
 * Generates ApolloClient object
 * @param {Object} methods Methods integrated with ApolloCLient object
 * @param {function} methods.logout Function to logout
 */

export default function getApolloClient({ logout }) {
  const cache = new InMemoryCache()

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token =
      getItem('token', 'PERSISTENT') || getItem('token', 'TEMPORARY')
    // return the headers to the context so httpLink can read them

    /* eslint-disable indent */
    return token
      ? {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
          }
        }
      : {
          headers: { ...headers }
        }
    /* eslint-enable indent */
  })

  function encryptAES(text) {
    var textBytes = aesjs.utils.utf8.toBytes(text)
    var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5))
    var encryptedBytes = aesCtr.encrypt(textBytes)
    var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes)
    return encryptedHex
  }
  function decryptAES(encryptedHex) {
    var encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex)
    var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5))
    var decryptedBytes = aesCtr.decrypt(encryptedBytes)
    var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes)
    return decryptedText
  }

  const fetchApiReponse = async (url, reqBody, headers) => {
    try {
      const resp = await axios.post(url, reqBody, { headers })
      //console.log("resp", resp);

      const __resp = resp.data
      //console.log("__resp", __resp);

      const __decryptData = decryptAES(__resp['Payload'])
      // console.log("__decryptData", __decryptData);

      resp.data = JSON.parse(__decryptData)
      //console.log("resp.data =====>", resp.data);
      return Promise.resolve(createResponseFromAxiosResp(resp, resp))
    } catch (error) {
      // Handle any errors
      // logout();
      throw error // Re-throw the error to be caught by the caller
    }
  }

  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_SERVER_WS_URI,
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: () => ({
        Authorization: `Bearer ${getItem('token', 'PERSISTENT') ||
          getItem('token', 'TEMPORARY')}`
      })
    }
  })

  wsLink.subscriptionClient.onReconnecting(() => {
    const authStatus = isAuthenticated()

    if (!authStatus) {
      wsLink.subscriptionClient.unsubscribeAll()
      wsLink.subscriptionClient.close()
      logout()
    }
  })

  const httpLink = createUploadLink({
    uri: process.env.REACT_APP_SERVER_HTTP_URI,
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(error => {
          if (error.message.toLowerCase().includes('auth')) {
            console.error('Auth error', error.message)
          }
        })
      }
      if (networkError) {
        console.error('Network Error')
      }
    },
    fetch: (uri, option) => {
      const headers = {
        'Content-Type':
          option.body instanceof FormData
            ? 'multipart/form-data'
            : 'application/json',
        authorization: `Bearer ${getItem('token', 'PERSISTENT') ||
          getItem('token', 'TEMPORARY')}`
      }

      let reqBody

      if (option.body instanceof FormData) {
        reqBody = option.body
      } else {
        // Handle JSON encryption
        let __body = option.body
        reqBody = {
          Payload: encryptAES(__body)
        }
      }
      const ResponseData = fetchApiReponse(uri, reqBody, headers)
      return ResponseData
    }
  })

  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    authLink.concat(httpLink)
  )

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      )
    }
    if (networkError) {
      console.log(`[Network error]: ${JSON.stringify(networkError)}`)
    }
  })

  const logoutLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        if (error.message.toLowerCase().includes('auth')) {
          logout()
        }
      })
    }
  })

  const client = new ApolloClient({
    link: ApolloLink.from([
      ...(process.env.NODE_ENV !== 'production' ? [errorLink] : []),
      logoutLink,
      link
    ]),
    cache
  })

  return client
}
