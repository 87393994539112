/**
 * An object storing the configuration for categories in the menu drawer
 * @module drawerCategories
 * @summary Drawer categories configuration
 */

export default {
  HOME: {
    name: 'Home',
    key: 'HOME',
    order: 1
  },
  ACTIONS: {
    name: 'Actions & Events',
    key: 'ACTIONS',
    order: 2
  },
  GEO: {
    name: 'Geo Services',
    key: 'GEO',
    order: 3
  },
  INTEGRATIONS: {
    name: 'Integrations',
    key: 'INTEGRATIONS',
    order: 4
  },
  MANAGEMENT: {
    name: 'Management',
    key: 'MANAGEMENT',
    order: 5
  }
}
